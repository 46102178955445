import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Modal, Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Product, ProductRecap } from '../components'

const { app, products, newEl, productEdit, categoryEdit, corpEdit } = routes

class Products extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            wait: true,
            deposit: -1,
            exportModal: false,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteProduct, page, items, search } = this.state
        let { organization } = this.props

        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteProduct.fetching !== prevState.deleteProduct.fetching && !deleteProduct.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getProductList(page, items, search.data)
        } else if (this.state.search !== prevState.search) {
            this.setState({
                page: 0,
            })
            this.state.network.getProductList(0, items, search.data)
        }
    }

    next() {
        let { products, items } = this.state

        if (!products.product || products.product.length < items) {
            return
        }

        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { sub, network } = this.props
        let { page, items } = this.state

        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []
        let extraRightTools = []

        if (user && !!user.manage_job) {
            extraTools.push(
                {
                    name: 'Crea nuovo prodotto',
                    icon: 'add',
                    // disabled: () => true,
                    action: () => {
                        this.props.history.push(route_from(app, products, newEl, productEdit))
                    },
                },
                {
                    name: 'Crea nuova categoria',
                    icon: 'add',
                    // disabled: () => true,
                    action: () => {
                        this.props.history.push(route_from(app, products, newEl, categoryEdit))
                    },
                },
                {
                    name: 'Crea nuova tipologia',
                    icon: 'add',
                    // disabled: () => true,
                    action: () => {
                        this.props.history.push(route_from(app, products, newEl, corpEdit))
                    },
                }
            )

            // TODO create import product from CSV file!
            // extraRightTools.push({
            // 	name: 'Importa prodotti',
            // 	icon: 'upload',
            // 	position: 'none',
            // 	// disabled: () => true,
            // 	action: () => {
            // 		this.setState({ importModal: true })
            // 	}
            // })
        }

        if (!sub) {
            this.props.toolbarResult([
                ...extraTools,
                {
                    name: 'Esporta prodotti',
                    icon: 'download',
                    position: 'right',
                    action: () => {
                        this.setState({ exportModal: true })
                    },
                },
                ...extraRightTools,
                {
                    name: '',
                    icon: 'arrow left',
                    position: 'none',
                    action: () => {
                        this.prev()
                    },
                },
                {
                    name: '',
                    icon: 'arrow right',
                    position: 'none',
                    action: () => {
                        this.next()
                    },
                },
            ])
        }

        network.getProductList(page, items, this.state.search.data)
    }

    renderProduct(key, product) {
        let { selected } = this.state
        let { onChange, selectable, noActions, showNoProduct } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (product) => {
                if (onChange) {
                    onChange(product)
                }
                this.setState({ selected: product })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        if (showNoProduct) {
            extraProps.showNoProduct = true
        }

        return <Product {...extraProps} selected={product.code === selected.code} type="full" key={`product_${key}`} product={product} />
    }

    render() {
        let { products, fetching, deposit, depositList } = this.state
        let { onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        // console.log('Deposit list is', depositList)
        let options = []

        if (depositList) {
            for (deposit of depositList) {
                options.push({ key: deposit.id, value: deposit.id, text: deposit.name })
            }
        }

        options.push({ key: -1, value: -1, text: 'Tutti' })

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View noflex fullw column>
                    {placeholders}
                </View>
            )
        } else if (products.product) {
            content = Object.keys(products.product).map((key) => {
                let value = products.product[key]
                return this.renderProduct(key, value)
            })
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                <Box textStyle="group" style={{ paddingTop: 16 }} centered content={content} />
                {this.renderExportModal()}
            </View>
        )
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    renderExportModal() {
        let { exportModal, wait, deposit } = this.state

        return (
            <Modal open={exportModal}>
                <Modal.Header>Esportazione prodotti</Modal.Header>
                <Modal.Content>Premi il pulsante calcola per avviare il processo, poi scarica quando e{'\''} tutto pronto</Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        icon
                        onClick={() => {
                            this.setState({ wait: true, exportModal: false })
                        }}
                    >
                        Annulla <Icon name="cancel" />
                    </Button>
                    <Button
                        primary
                        icon
                        onClick={() => {
                            this.setState({ wait: false })
                        }}
                    >
                        Calcola <Icon name="computer" />
                    </Button>
                    <ProductRecap
                        deposit={deposit}
                        wait={wait}
                        onDone={(data) => {
                            // console.log('On done called', data)
                            this.setState({ wait: true })
                        }}
                        element={
                            <Button
                                primary
                                icon
                                onClick={() => {
                                    this.setState({ wait: true, exportModal: false })
                                }}
                            >
                                Scarica <Icon name="download" />
                            </Button>
                        }
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getProductList, deleteProduct, search, organization, login } = state

    let productList = getProductList

    return {
        products: productList.data,
        fetching: productList.fetching,
        deleteProduct,
        search,
        login,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Products)))
