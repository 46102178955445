import { useDisclosure } from '@chakra-ui/hooks'
import { Center, Grid } from '@chakra-ui/layout'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { Button, Icon } from 'semantic-ui-react'
import { baseViewDispatch } from '../lib/util'
import GridCell from './GridCell'
function PackageRow({ packageCode, packageDescription, pcsPerPackage }) {
    return (
        <>
            <GridCell colSpan={7} title="Imballo" text={`${packageDescription}`} />
            <GridCell colSpan={3} title="QTA Imballo" text={`${pcsPerPackage}`} />
        </>
    )
}

function MasterRow({ articleQuantity, articleWeight, masterCode, masterName, masterPercent }, noBarcode) {
    if (noBarcode) {
        return (
            <>
                <GridCell colSpan={5} title="MASTER" text={`${masterName}`} />
                <GridCell colSpan={3} title="g MASTER" text={`${Math.round(articleWeight * (masterPercent / 100) * articleQuantity * 100) / 100}g`} />
                <GridCell colSpan={2} title="% MASTER" text={`${masterPercent}`} />
            </>
        )
    } else {
        return (
            <>
                {noBarcode && <GridCell colSpan={5} rowSpan={1} title="" text={masterCode} />}
                {!noBarcode && <GridCell colSpan={5} rowSpan={2} title="" barcode={`summary_master_${masterCode.replace(/[\s%()]/g, '')}`} />}
                {/* <GridCell colSpan={5} title="" text={`${masterCode}`} /> */}
                <GridCell
                    colSpan={3}
                    rowSpan={noBarcode ? 1 : 2}
                    title=""
                    text={`${Math.round(articleWeight * (masterPercent / 100) * articleQuantity * 100) / 100}g`}
                />
                <GridCell colSpan={2} rowSpan={noBarcode ? 1 : 2} title="" text={masterPercent} />
            </>
        )
    }
}

function MaterialRow({ articleQuantity, articleWeight, materialCode, materialDescription, materialPercent }, noBarcode) {
    if (noBarcode) {
        return (
            <>
                <GridCell colSpan={5} title="MATERIALE" text={`${materialDescription}`} />
                <GridCell
                    colSpan={3}
                    title="Kg MATERIALE"
                    text={`${Math.round(((articleWeight * (materialPercent / 100) * articleQuantity) / 1000) * 100) / 100}Kg`}
                />
                <GridCell colSpan={2} title="% MATERIALE" text={`${materialPercent}`} />
            </>
        )
    } else {
        return (
            <>
                {/* <GridCell colSpan={5} title="" text={`${materialCode}: ${materialDescription}`} /> */}
                {noBarcode && <GridCell colSpan={5} rowSpan={2} title="" text={materialCode} />}
                {!noBarcode && <GridCell colSpan={5} rowSpan={2} title="" barcode={`summary_material_${materialCode.replace(/[\s%()]/g, '')}`} />}
                {/* <GridCell colSpan={5} title="" text={`${materialCode}`} /> */}
                <GridCell colSpan={3} rowSpan={2} title="" text={`${Math.round(((articleWeight * (materialPercent / 100)) / 1000) * 100) / 100}Kg`} />
                <GridCell colSpan={2} rowSpan={2} title="" text={materialPercent} />
            </>
        )
    }
}

const updateBarcode = (id, text) => {
    if (document.getElementById(`${id}`)) {
        JsBarcode(`#${id}`, text, {
            format: 'CODE128',
            height: 100,
            displayValue: true,
            // lineColor: '#0aa',
            width: 3,
            // height: 40,
            // displayValue: false,
        })
    }
}

function SummarySheet(props) {
    const { network, toolbarResult, filtersResult, sortsResult, isEmployee, objectToSerialize: s, type = 'workorder' } = props

    const { isOpen, onOpen, onClose } = useDisclosure()
    const componentRef = useRef()

    // useEffect(() => {
    //     initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    // }, [])

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                switch (type) {
                    default:
                    case 'workorder':
                        updateBarcode(`summary_${s.workOrderId}`, s.workOrderInternalId ? s.workOrderInternalId : s.workOrderId)
                        updateBarcode(`summary_${s.articleId}`, s.workCode)
                        updateBarcode(`summary_${s.jobCode}`, s.jobName ? s.jobName : s.jobCode)

                        if (s.materials) {
                            for (const material of s.materials) {
                                updateBarcode(`summary_material_${material.materialCode.replace(/[\s%()]/g, '')}`, material.materialCode)
                            }
                        }
                        if (s.masters) {
                            for (const master of s.masters) {
                                updateBarcode(`summary_master_${master.masterCode.replace(/[\s%()]/g, '')}`, master.masterCode)
                            }
                        }
                        break
                    case 'article':
                        updateBarcode(`summary_article_${s.articleId}`, s.articleCode)
                        break
                    case 'mold':
                        updateBarcode(`summary_mold_${s.moldCode}`, s.moldCode)
                        break
                    case 'master':
                        updateBarcode(`summary_master_${s.masterId}`, s.masterCode)
                        break
                    case 'material':
                        updateBarcode(`summary_material_${s.materialId}`, s.materialCode)
                        break
                    case 'package':
                        updateBarcode(`summary_package_${s.packageId}`, s.packageCode)
                        break
                    case 'kit':
                        updateBarcode(`summary_kit_${s.kitId}`, s.kitCode)
                        break
                    case 'production':
                        updateBarcode(`summary_production_${s.productionId}`, s.productionId)
                        updateBarcode(`summary_production_mold_${s.moldCode}`, s.moldCode)
                        updateBarcode(`summary_production_machine_${s.machineId}`, s.machineId)
                        break
                }
            }, 1000)
        }
    }, [s, isOpen])

    if (!s) {
        return <Center>ERRORE</Center>
    }

    let imageToShow = s.image
    if (s && s.articleImages && s.articleImages[s.articleImageIndexToPick]) {
        imageToShow = s.articleImages[s.articleImageIndexToPick].picturePath
        // console.log('Image to show is', imageToShow)
    }

    if (s && s.molds && s.molds.length > 0 && s.molds[0].image) {
        imageToShow = s.molds[0].image
    }

    let content = null
    switch (type) {
        default:
        case 'workorder':
            content = (
                <Grid
                    className="perma-light-mode"
                    ref={componentRef}
                    border="1px"
                    ml="9px"
                    mt="9px"
                    w="21cm"
                    minH="27cm"
                    maxH="27cm"
                    h="27cm"
                    templateRows="repeat(27, 1fr)"
                    templateColumns="repeat(10, 1fr)"
                    gap={0}
                >
                    <GridCell colSpan={5} title="Articolo" text={s.workCode} />
                    <GridCell colSpan={5} title="Cliente" text={s.customerName ? s.customerName : s.customer ? s.customer.customerName : ''} />

                    {/* <GridCell colSpan={2} title="Ordine cliente" text={s.jobCode} />

                    <GridCell colSpan={2} title="Ordine interno" text={s.workOrderId} /> */}

                    <GridCell colSpan={5} title="DESCRIZIONE" text={s.articleName ? s.articleName : s.workOrderDescription} />
                    <GridCell colSpan={5} title="COD Ordine Interno" text={s.workOrderId} />
                    <GridCell colSpan={5} rowSpan={5} title="FOTO" image={imageToShow} />
                    <GridCell colSpan={5} title="COD Ordine Cliente" text={s.jobName ? s.jobName : s.jobCode} />
                    <GridCell colSpan={5} title="DATA Ordine" text={s.orderDate ? moment(s.orderDate).format('DD/MM/YYYY') : '--'} />
                    <GridCell colSpan={5} title="DATA Consegna" text={moment(s.deliveryDate).format('DD/MM/YYYY')} />
                    <GridCell colSpan={5} rowSpan={1} title="QTA Ordinata" text={Math.round(s.productQuantity)} />
                    {s.molds && s.molds.length > 0 && (
                        <>
                            <GridCell colSpan={3} title="CODICE STAMPO" text={s.molds[0].moldCode} />
                            <GridCell
                                colSpan={2}
                                title="Posizione Stampo"
                                text={s.molds[0].row && s.molds[0].column ? `Riga ${s.molds[0].row} Colonna ${s.molds[0].column}` : `N/A`}
                            />
                        </>
                    )}
                    {(!s.molds || !s.molds.length) && (
                        <>
                            <GridCell colSpan={3} title="CODICE STAMPO" text={s.jobName ? s.jobName : s.jobCode} />
                            <GridCell colSpan={2} title="Posizione Stampo" text={s.row && s.column ? `Riga ${s.row} Colonna ${s.column}` : `N/A`} />
                        </>
                    )}

                    {/* <GridCell colSpan={5} title="" text="MASTER" />
                    <GridCell colSpan={3} title="" text="Kg Master" />
                    <GridCell colSpan={2} title="" text="% Master" /> */}
                    {s.masters ? (
                        s.masters.map((master, index) => {
                            return MasterRow(
                                { ...master, articleQuantity: s.productQuantity ? s.productQuantity : 1, articleWeight: s.articleWeight },
                                true
                            )
                        })
                    ) : (
                        <GridCell colSpan={10} title="" text="Nessun master mappato" />
                    )}

                    {/* <GridCell colSpan={5} title="" text="MATERIALI" />
                    <GridCell colSpan={3} title="" text="Kg Materiale" />
                    <GridCell colSpan={2} title="" text="% Materiale" /> */}
                    {s.materials ? (
                        s.materials.map((value, index) => {
                            return MaterialRow(
                                { ...value, articleQuantity: s.productQuantity ? s.productQuantity : 1, articleWeight: s.articleWeight },
                                true
                            )
                        })
                    ) : (
                        <GridCell colSpan={10} title="" text="Nessun materiale mappato" />
                    )}

                    {/* <GridCell colSpan={7} title="Imballo" text="" />
                    <GridCell colSpan={3} title="QTA Imballo" text="" /> */}
                    {s.packs ? (
                        s.packs.map((value, index) => {
                            return PackageRow(value)
                        })
                    ) : (
                        <GridCell colSpan={10} title="Imballo" text="Nessun imballo mappato" />
                    )}

                    {/* <GridCell colSpan={10} title="" text="Codice a barre articolo" />
                    <GridCell colSpan={10} rowSpan={2} title="" barcode={`summary_${s.articleId}`} />

                    <GridCell colSpan={10} title="" text="Ordine cliente" />
                    <GridCell colSpan={10} rowSpan={2} title="" barcode={`summary_${s.jobCode}`} />

                    <GridCell colSpan={10} title="" text="Ordine interno" />
                    <GridCell colSpan={10} rowSpan={2} title="" barcode={`summary_${s.workOrderId}`} /> */}

                    <GridCell colSpan={10} rowSpan={2} title="NOTA ARTICOLO" text={s.articleNote} />
                    <GridCell colSpan={10} rowSpan={2} title="NOTA ORDINE" text={s.workOrderNotes} />

                    <GridCell colSpan={10} title="" text="CHECK LIST" />
                    <GridCell colSpan={3} title="" text="" />
                    <GridCell colSpan={7} title="" text="Controllo giacenza magazzino" />
                    <GridCell colSpan={3} title="" text="" />
                    <GridCell colSpan={7} title="" text="Controllo macinato" />
                    <GridCell colSpan={3} title="" text="" />
                    <GridCell colSpan={7} title="" text="Preparazione materiale" />
                    <GridCell colSpan={3} title="" text="" />
                    <GridCell colSpan={5} title="" text="Messa in macchina Stampo" />
                    <GridCell colSpan={2} title="" text="" />
                    <GridCell colSpan={3} title="" text="" />
                    <GridCell colSpan={7} title="" text="Controlla Datario" />
                    <GridCell colSpan={3} title="" text="" />
                    <GridCell colSpan={7} title="" text="RESET Contapezzi" />
                    {/* <GridCell colSpan={3} title="" text="" />
                    <GridCell colSpan={7} title="" text="Imballaggio" />
                    <GridCell colSpan={3} title="" text="" />
                    <GridCell colSpan={7} title="" text="Etichettatura" /> */}
                </Grid>
            )
            break
        case 'article':
            content = (
                <Grid
                    className="perma-light-mode"
                    ref={componentRef}
                    border="1px"
                    w="23cm"
                    minH="29.7cm"
                    templateRows="repeat(27, 1fr)"
                    templateColumns="repeat(10, 1fr)"
                    gap={0}
                >
                    <GridCell colSpan={10} title="CODICE" text={s.articleCode} />
                    <GridCell colSpan={10} title="DESCRIZIONE" text={s.articleDescription} />
                    <GridCell colSpan={10} rowSpan={5} title="Codice a barre" barcode={`summary_article_${s.articleId}`} />
                </Grid>
            )
            break
        case 'mold':
            content = (
                <Grid
                    className="perma-light-mode"
                    ref={componentRef}
                    border="1px"
                    w="23cm"
                    minH="29.7cm"
                    templateRows="repeat(27, 1fr)"
                    templateColumns="repeat(10, 1fr)"
                    gap={0}
                >
                    <GridCell colSpan={10} title="CODICE" text={s.moldCode} />
                    <GridCell colSpan={10} title="DESCRIZIONE" text={s.moldDescription} />
                    <GridCell colSpan={10} rowSpan={5} title="Codice a barre" barcode={`summary_mold_${s.moldCode}`} />
                </Grid>
            )
            break
        case 'master':
            content = (
                <Grid
                    className="perma-light-mode"
                    ref={componentRef}
                    border="1px"
                    w="23cm"
                    minH="29.7cm"
                    templateRows="repeat(27, 1fr)"
                    templateColumns="repeat(10, 1fr)"
                    gap={0}
                >
                    <GridCell colSpan={10} title="CODICE" text={s.masterCode} />
                    <GridCell colSpan={10} title="DESCRIZIONE" text={s.masterName} />
                    <GridCell colSpan={10} rowSpan={5} title="Codice a barre" barcode={`summary_master_${s.masterId}`} />
                </Grid>
            )
            break
        case 'material':
            content = (
                <Grid
                    className="perma-light-mode"
                    ref={componentRef}
                    border="1px"
                    w="23cm"
                    minH="29.7cm"
                    templateRows="repeat(27, 1fr)"
                    templateColumns="repeat(10, 1fr)"
                    gap={0}
                >
                    <GridCell colSpan={10} title="CODICE" text={s.materialCode} />
                    <GridCell colSpan={10} title="DESCRIZIONE" text={s.materialDescription} />
                    <GridCell colSpan={10} rowSpan={5} title="Codice a barre" barcode={`summary_material_${s.materialId}`} />
                </Grid>
            )
            break
        case 'package':
            content = (
                <Grid
                    className="perma-light-mode"
                    ref={componentRef}
                    border="1px"
                    w="23cm"
                    minH="29.7cm"
                    templateRows="repeat(27, 1fr)"
                    templateColumns="repeat(10, 1fr)"
                    gap={0}
                >
                    <GridCell colSpan={10} title="CODICE" text={s.packageCode} />
                    <GridCell colSpan={10} title="DESCRIZIONE" text={s.packageDescription} />
                    <GridCell colSpan={10} rowSpan={5} title="Codice a barre" barcode={`summary_package_${s.packageId}`} />
                </Grid>
            )
            break
        case 'kit':
            content = (
                <Grid
                    className="perma-light-mode"
                    ref={componentRef}
                    border="1px"
                    w="23cm"
                    minH="29.7cm"
                    templateRows="repeat(27, 1fr)"
                    templateColumns="repeat(10, 1fr)"
                    gap={0}
                >
                    <GridCell colSpan={10} title="CODICE" text={s.kitCode} />
                    <GridCell colSpan={10} title="DESCRIZIONE" text={s.kitDescription} />
                    <GridCell colSpan={10} title="" text="" />
                    <GridCell colSpan={10} rowSpan={5} title="Codice a barre" barcode={`summary_kit_${s.kitCode}`} />
                </Grid>
            )
            break
        case 'production':
            content = (
                <Grid
                    className="perma-light-mode"
                    ref={componentRef}
                    border="1px"
                    w="23cm"
                    minH="29.7cm"
                    templateRows="repeat(27, 1fr)"
                    templateColumns="repeat(10, 1fr)"
                    gap={0}
                >
                    <GridCell colSpan={10} title="CODICE" text={s.productionId} />
                    <GridCell colSpan={10} rowSpan={5} title="Codice a barre" barcode={`summary_production_${s.productionId}`} />
                    <GridCell colSpan={10} title="CODICE STAMPO" text={s.moldCode} />
                    <GridCell colSpan={10} rowSpan={5} title="Codice a barre" barcode={`summary_production_mold_${s.moldCode}`} />
                    <GridCell colSpan={10} title="CODICE MACCHINA" text={s.machineId} />
                    <GridCell colSpan={10} rowSpan={5} title="Codice a barre" barcode={`summary_production_machine_${s.machineId}`} />
                </Grid>
            )
            break
    }

    return (
        <>
            <Button icon color="grey" onClick={onOpen}>
                <Icon name="file excel outline" />
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW="25cm">
                    <ModalHeader>Scheda</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody w="25cm" minH="29.7cm">
                        <ReactToPrint
                            documentTitle={'Scheda'}
                            trigger={() => {
                                return <Button>Stampa</Button>
                            }}
                            content={() => componentRef.current}
                        />
                        {content}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteMold, mergeMold } = state

    return {
        getMachineList,
        deleteMold,
        mergeMold,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(SummarySheet)))
