const path = require('path')

//vfstampi/commesse/d032/consuntivo

export const routes = {
    login: 'login',

    pdfexport: 'pdfexport',
    dataimport: 'dataimport',
    moldmapping: 'moldmapping',

    collections: 'collections',
    families: 'families',
    articles: 'articles',
    variants: 'variants',
    ordering: 'ordering',
    assignvariants: 'assignvariants',
    offices: 'offices',
    duplicates: 'duplicates',
    realtimedata: 'realtimedata',

    tasks: 'tasks',

    collectionEdit: 'collectionEdit',
    familyEdit: 'familyEdit',
    articleEdit: 'articleEdit',
    variantEdit: 'variantEdit',
    officeEdit: 'officeEdit',

    app: 'app',
    jobs: 'jobs',
    job: 'job',
    works: 'works',
    employees: 'employees',
    machines: 'machines',
    contracts: 'contracts',
    deposits: 'deposits',
    products: 'products',
    categories: 'categories',
    corps: 'corps',
    warehouse: 'warehouse',
    monitor: 'monitor',
    admin: 'admin',
    jobEdit: 'jobEdit',
    jobMerge: 'jobMerge',
    newEl: 'newEl',
    turnEdit: 'turnEdit',
    userEdit: 'userEdit',
    workerEdit: 'workerEdit',
    machineEdit: 'machineEdit',
    productEdit: 'productEdit',
    categoryEdit: 'categoryEdit',
    depositEdit: 'depositEdit',
    contractEdit: 'contractEdit',
    corpEdit: 'corpEdit',
    costEdit: 'costEdit',
    costUpdate: 'costUpdate',
    finalBalance: 'finalBalance',
    dashboard: 'dashboard',
    roles: 'roles',
    stock: 'stock',
    documents: 'documents',
    documentEdit: 'documentEdit',
    stockEdit: 'stockEdit',
    dataexport: 'dataexport',
    settings: 'settings',
    turns: 'turns',
    help: 'help',
    about: 'about',
    tempJobs: 'tempJobs',
    organizations: 'organizations',
    message: 'message',
    saldatrice: 'saldatrice',
    babypressa: 'babypressa',
    gt2000: 'gt2000',
    saldatriceeditor: 'saldatriceeditor',
    oeenegribossia: 'oeenegribossia',
    oeenegribossib: 'oeenegribossib',
    oeenegribossic: 'oeenegribossic',
    oeenegribossid: 'oeenegribossid',
    negribossia: 'negribossia',
    negribossib: 'negribossib',
    negribossic: 'negribossic',
    negribossid: 'negribossid',
    oeemachine1: 'oeemachine1',
    machine1: 'machine1',
    wittman: 'wittman',

    daconsole: 'daconsole',
    pin: 'pin',
    employee: 'employee',
    insertion: 'insertion',
    terminal: 'terminal',
    calendarView: 'calendarView',
    profile: 'profile',

    workerPanel: 'workerPanel',

    clientOrders: 'clientOrders',
    clientOrder: 'clientOrder',
    clientOrderEdit: 'clientOrderEdit',

    taskdashboard: 'taskdashboard',
    taskdetail: 'taskdetail',

    scheduleEditor: 'scheduleEditor',
    calendarEditor: 'calendarEditor',
    badgescan: 'badgescan',
    badge: 'badge',

    masters: 'masters',
    materials: 'materials',
    packages: 'packages',
    kits: 'kits',
    molds: 'molds',
    materialcheck: 'materialcheck',
    customers: 'customers',
    printjobs: 'printjobs',
    printjobsdetail: 'printjobsdetail',
    printjobshistory: 'printjobshistory',
    jobwizard: 'jobwizard',
    ind40visualizer: 'ind40visualizer',

    fileimport: 'fileimport',

    workorderprint: 'workorderprint',
    artiprint: 'artiprint',
    productions: 'productions',
    articleClone: 'articleClone',
    
    labeling: 'Stampa etichette',
    labels: 'Etichette',
    labelEdit: 'Modifica etichetta',
    packaging: 'Imballaggio e verifica',
    warehouselabels: 'Stampa etichette magazzino',
    productionlabels: 'Stampa etichette produzione',
    customlabels: 'Stampa etichette personalizzate',

    listings: 'listings',
}

export const routeNames = {
    dataimport: 'Importazione dati',
    pdfexport: 'Esportazione PDF',
    moldmapping: 'Mappatura stampi',

    collections: 'Collezioni',
    families: 'Famiglie',
    articles: 'Articoli',
    variants: 'Varianti',
    ordering: 'Ordinamento',
    assignvariants: 'Assegna varianti',
    offices: 'Punti vendita',
    duplicates: 'Assegna Duplicati',
    realtimedata: 'Dati in tempo reale',

    tasks: 'Task',

    collectionEdit: 'Modifica collezione',
    familyEdit: 'Modifica famiglia',
    articleEdit: 'Modifica articolo',
    variantEdit: 'Modifica variante',
    officeEdit: 'Modifica sede',

    login: '/',
    app: '/Syncode',
    daconsole: '/Console',
    employee: '/Impiegato',
    insertion: '/Inserimento',
    terminal: 'Dati produzione',
    finalBalance: '/Consuntivo',
    dashboard: 'Pannello',
    roles: 'Ruoli e permessi',
    products: 'Prodotti',
    documents: 'Documenti',
    productEdit: 'Modifica Prodotto',
    documentEdit: 'Modifica Documento',
    categoryEdit: 'Modifica categoria',
    depositEdit: 'Modifica deposito',
    corpEdit: 'Modifica corpo',
    contractEdit: 'Modifica appalto',
    stock: 'Magazzino',
    stockEdit: 'Modifica giacenza nel magazzino',
    dataexport: 'Esportazione dati',
    settings: 'Impostazioni',
    turns: 'Turni di lavoro',
    help: 'Aiuto',
    about: 'Informazioni',
    tempJobs: 'Commesse da ricontrollare',
    pin: 'Pin',
    jobs: 'Commesse',
    job: 'Commessa',
    works: 'Lavori',
    employees: 'Impiegati',
    machines: 'Macchine',
    contracts: 'Appalti',
    corps: 'Tipologie',
    categories: 'Categorie',
    deposits: 'Depositi',
    warehouse: 'Magazzino Elettronico',
    admin: 'Amministratori',
    jobEdit: 'Editor commessa',
    jobMerge: 'Unione commesse',
    saldatriceeditor: 'Invio dati a saldatrice',
    newEl: 'Nuovo',
    turnEdit: 'Editor turno',
    userEdit: 'Editor utente',
    workerEdit: 'Editor impiegato',
    machineEdit: 'Editor macchina',
    costEdit: 'Editor costi',
    costUpdate: 'Modifica costo',
    calendarView: '/Calendario',
    producers: 'Produttori',
    requests: 'Richieste',
    organizations: 'Rubrica',
    message: 'Messaggi',
    profile: 'Profilo',
    gt2000: 'C.B. Ferrari GT2000',
    monitor: 'C.B. Ferrari',
    saldatrice: 'Saldatrice SWA300',
    babypressa: 'Baby Pressa',
    oeenegribossia: 'OEE NegriBossi 1',
    oeenegribossib: 'OEE NegriBossi 2',
    negribossia: 'NegriBossi 1',
    negribossib: 'NegriBossi 2',
    oeemachine1: 'OEE Macchina 1',
    machine1: 'Macchina 1',
    wittman: 'Wittman Battenfeld',

    workerPanel: 'Pannello Impiegato',

    clientOrders: 'Ordini cliente',
    clientOrder: 'Ordine cliente',
    clientOrderEdit: 'Modifica ordine cliente',

    taskdashboard: 'Dashboard Task',
    taskdetail: 'Dettaglio Task',

    scheduleEditor: 'Editor di periodi',
    calendarEditor: 'Editor calendario',
    badgescan: 'Scansioni Badge',
    badge: 'Gestione Badge',

    masters: 'Master',
    materials: 'Materiali',
    packages: 'Imballi',
    kits: 'Kit',
    molds: 'Stampi',
    materialcheck: 'Controllo materiale',
    customers: 'Clienti',
    printjobs: 'Ordini stampaggio',
    printjobsdetail: 'Articoli da stampare',
    printjobshistory: 'Storico stampaggio',
    jobwizard: 'Creazione ordine cliente',
    ind40visualizer: 'Industria 4.0',

    fileimport: 'Importa file',
    workorderprint: 'Scheda ordine stampaggio',
    artiprint: 'Scheda articolo',
    productions: 'Produzioni',
    articleClone: 'Clona articolo',

    listings: 'Listini'
}

export const route_from = (...args) => {
    let toJoin = args.map((value) => {
        let name = routeNames[value]
        if (!name) {
            name = value
        }
        return name
    })
    return path.join(...toJoin)
}

export const is_in = (path, route) => {
    return path.includes(routeNames[route])
}

export const get_section = (path) => {
    for (let [key, value] of Object.entries(routeNames)) {
        if (path.includes(value)) return key
    }
    return Object.keys(routes)[0]
}
