export function getMachineList(search, isEmployee) {
    let { getMachineListRequest, getMachineListError, getMachineListResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_machine_list',
        this.createBundle(
            'get_machine_list',
            { search: search ? search : '' },
            this.GET,
            getMachineListRequest,
            getMachineListResult,
            getMachineListError,
            isEmployee
        )
    )
}
