import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Modal, Table } from 'semantic-ui-react'
import PackageEditor from '../job/PackageEditor'
import { baseViewDispatch, initializePage } from '../lib/util'
import Package from './Package'
function Packages(props) {
    const { network, table, time, label, field, format, toolbarResult, filtersResult, sortsResult, deletePackage, mergePackaging } = props
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        pack: null,
    })

    const [packs, setPackages] = useState([])
    const [dirty, setDirty] = useState(false)
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        network.getPackageList().then((data) => {
            // console.log(data)

            const packs = []
            for (const key in data.data) {
                packs.push(data.data[key])
            }
            setPackages(packs)
            setDirty(false)
        })
    }, [dirty, deletePackage.fetching, mergePackaging.fetching])

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    if (!packs || packs.length === 0) return null

    // console.log('Packages are', packs)
    return (
        <>
            <Table celled>
                <Package type="header" />
                <Table.Body>
                    {packs.map((value, index) => {
                        return (
                            <Package
                                key={value.packageId}
                                type="table"
                                pack={value}
                                onRowClick={(state) => {
                                    const { packageId } = state
                                    setEditing(packageId)
                                }}
                            />
                        )
                    })}
                </Table.Body>
            </Table>

            <Modal closeIcon open={!!editing} onClose={() => setEditing(false)}>
                <Modal.Header>Modificando pack</Modal.Header>
                <Modal.Content>
                    <PackageEditor
                        packageId={editing}
                        onSave={(pack) => {
                            setEditing(false)
                            setPackages([])
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deletePackage, mergePackaging } = state

    return {
        getMachineList,
        deletePackage,
        mergePackaging,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Packages)))
