import { connectNetwork } from 'lib/NetworkProvider'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Menu } from 'semantic-ui-react'
import { baseViewDispatch } from '../lib/util'

class MenuBar extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { toolbar, style } = this.props

        return (
            <>
                {toolbar.length > 0 && (
                    <Menu horizontal style={{ marginBottom: 0, ...style }}>
                        {toolbar.length > 0 &&
                            toolbar.map((tool, index) => (
                                <Menu.Item
                                    key={`menu_item_${index}`}
                                    as={!tool.type || tool.type === 'button' ? 'a' : 'span'}
                                    {...{
                                        disabled: tool.disabled ? tool.disabled() : false,
                                    }}
                                    icon={tool.icon}
                                    name={tool.name}
                                    onClick={tool.action}
                                    position={tool.position ? tool.position : 'left'}
                                    className={tool.selected && tool.selected() ? 'rowSelected noRightMargin' : 'noRightMargin'}
                                />
                            ))}
                    </Menu>
                )}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(MenuBar)))
