import { Box } from '@chakra-ui/layout'
import { Input } from '@chakra-ui/react'
import {} from 'containers'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import { route_from } from 'lib/routes'
import { routes } from 'lib/routes'
import { createAlert } from 'lib/util'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Checkbox, Icon, Modal, Popup, Table } from 'semantic-ui-react'
import { ImageDefault } from '../components'
import MachinePicker from '../job/MachinePicker'
import SummarySheet from '../job/SummarySheet'
import { baseViewDispatch, roundToDigits, isSorted } from '../lib/util'
import WorkOrderDropdown from '../utility/WorkOrderDropdown'

const { app, articles, articleEdit, jobs, jobEdit } = routes
class WorkOrder extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, workOrder } = props

        this.state = {
            deleting: false,
            ...workOrder,
            workOrderMinutes: workOrder ? parseFloat(workOrder.workOrderMinutes) : 0,
        }

        if (onCostChange && workOrder) {
            onCostChange(this.totalCost(), workOrder.workOrderId)
        }
    }

    totalCost() {
        let { workOrderPrice } = this.state
        let total = workOrderPrice
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { workOrder, workOrderId, network } = this.props

        if ((!workOrder || !workOrder.workOrderId) && workOrderId) {
            network.getWorkOrder(workOrderId)
        }
    }

    renderBalanceFooter() {
        return <Table.Row></Table.Row>
    }

    renderBalanceHeader() {
        return <Table.Header fullWidth></Table.Header>
    }

    renderForBalance() {
        return <Table.Row></Table.Row>
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let {
            hideWorkOrder,
            hideWorkOrderName,
            hideQuantity,
            hideDeliveryDate,
            isBalance,
            noWarehouse,
            noActions,
            hideBarcode,
            hideImage,
            selectable,
            onRowClick,
            sorts,
            hideWorkOrderInternal,
            onHeaderClick,
        } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            const headerClick = onHeaderClick ? (name) => onHeaderClick(name) : (name) => {}
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {selectable && <Table.HeaderCell>Sel</Table.HeaderCell>}
                        {!hideWorkOrderInternal && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'workOrderId')} onClick={() => headerClick('workOrderId')}>
                                Ordine interno
                            </Table.HeaderCell>
                        )}
                        {!hideWorkOrderName && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'jobCode')} onClick={() => headerClick('jobCode')}>
                                Ord. Cli. Interno
                            </Table.HeaderCell>
                        )}
                        {!hideWorkOrderName && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'jobCode')} onClick={() => headerClick('jobCode')}>
                                Ordine cliente
                            </Table.HeaderCell>
                        )}
                        {!hideDeliveryDate && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'deliveryDate')} onClick={() => headerClick('deliveryDate')}>
                                Data di consegna
                            </Table.HeaderCell>
                        )}
                        {!hideImage && <Table.HeaderCell>Foto</Table.HeaderCell>}
                        {!hideWorkOrderName && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'customerName')} onClick={() => headerClick('customerName')}>
                                Cliente
                            </Table.HeaderCell>
                        )}
                        {!hideWorkOrder && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'workCode')} onClick={() => headerClick('workCode')}>
                                Cod Arti
                            </Table.HeaderCell>
                        )}
                        {!hideWorkOrder && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'articleName')} onClick={() => headerClick('articleName')}>
                                Articolo
                            </Table.HeaderCell>
                        )}
                        {!hideQuantity && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'productQuantity')} onClick={() => headerClick('productQuantity')}>
                                Da produrre
                            </Table.HeaderCell>
                        )}
                        {!hideQuantity && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'producedQuantity')} onClick={() => headerClick('producedQuantity')}>
                                Prodotto
                            </Table.HeaderCell>
                        )}
                        {!hideQuantity && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'deliveredQuantity')} onClick={() => headerClick('deliveredQuantity')}>
                                Consegnato
                            </Table.HeaderCell>
                        )}
                        {!hideQuantity && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'stampedQuantity')} onClick={() => headerClick('stampedQuantity')}>
                                Bollato
                            </Table.HeaderCell>
                        )}
                        {!hideQuantity && (
                            <Table.HeaderCell sorted={isSorted(sorts, 'remainingQuantity')} onClick={() => headerClick('remainingQuantity')}>
                                Rimanente
                            </Table.HeaderCell>
                        )}
                        {!hideBarcode && <Table.HeaderCell>Codice a barre</Table.HeaderCell>}
                        {!noActions && !noWarehouse && <Table.HeaderCell>Magazzino</Table.HeaderCell>}
                        {!noActions && <Table.HeaderCell>Azioni</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    updateBarcode = () => {
        const { workOrderId, workOrderInternalId } = this.state
        try {
            const text = `${workOrderInternalId ? workOrderInternalId : workOrderId}`
            if (document.getElementById(`workorderbarcode${workOrderInternalId ? workOrderInternalId : workOrderId}`)) {
                JsBarcode(`#workorderbarcode${workOrderInternalId ? workOrderInternalId : workOrderId}`, text, {
                    format: 'CODE128',
                    // lineColor: '#0aa',
                    // width: 4,
                    // height: 40,
                    // displayValue: false,
                })
            } else {
                setImmediate(this.updateBarcode)
            }
        } catch (e) {
            console.error(e)
        }
    }

    renderForTable() {
        let {
            hideWorkOrder,
            hideWorkOrderName,
            hideQuantity,
            hideDeliveryDate,
            hideBarcode,
            hideImage,
            isBalance,
            onRowClick,
            noWarehouse,
            noActions,
            selectable,
            onSelected,
            selected,
            network,
            success,
            error,
        } = this.props
        let {
            articleArticleId,
            articleId,
            workOrderId,
            workOrderInternalId,
            workCode,
            jobCode,
            jobName,
            productQuantity,
            deliveredQuantity,
            producedQuantity,
            stampedQuantity,
            deliveryDate,
            articleImages,
            image: moldImage,
            inProduction,
            molds,
            customerName,
            articleName,
            articleCode,
            workOrderDescription,
            customer,
        } = this.state

        setImmediate(this.updateBarcode)

        let scanned = this.props.scannedWorkOrders ? this.props.scannedWorkOrders.includes(workCode) : false
        let momobj = moment(deliveryDate)
        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row style={{ backgroundColor: scanned ? 'green' : 'white' }}>
                    {selectable && (
                        <Table.HeaderCell>
                            <Checkbox
                                style={{ marginLeft: 12 }}
                                onChange={(e, data) => {
                                    if (onSelected) {
                                        onSelected(workOrderId, data.checked, this.state)
                                    }
                                }}
                                checked={selected}
                            />
                        </Table.HeaderCell>
                    )}
                    {!hideWorkOrder && <Table.Cell>{workOrderInternalId ? workOrderInternalId : workOrderId}</Table.Cell>}
                    {!hideWorkOrderName && (
                        <Table.Cell>
                            <a href={route_from(app, jobs, jobEdit, `${jobCode.replaceAll('/', '__slash__')}`)}>{jobCode}</a>
                        </Table.Cell>
                    )}
                    {!hideWorkOrderName && (
                        <Table.Cell>
                            <a href={route_from(app, jobs, jobEdit, `${jobCode.replaceAll('/', '__slash__')}`)}>{jobName ? jobName : jobCode}</a>
                        </Table.Cell>
                    )}
                    {!hideDeliveryDate && <Table.Cell>{momobj.isValid() ? momobj.format('DD/MM/YYYY') : '--'}</Table.Cell>}
                    {(!molds || molds.length === 0 || !molds[0].image) && !hideImage && (
                        <Table.Cell>
                            {articleImages && articleImages.length > 0 && (
                                <div style={styles.imageContainer}>
                                    <ImageDefault fromRoot src={articleImages[0].picturePath} ui={false} style={styles.workOrderImage} />
                                </div>
                            )}
                            {!articleImages ||
                                (articleImages.length === 0 && (
                                    <div style={styles.imageContainer}>
                                        <ImageDefault fromRoot src={moldImage} ui={false} style={styles.workOrderImage} />
                                    </div>
                                ))}
                        </Table.Cell>
                    )}
                    {molds && molds.length > 0 && molds[0].image && !hideImage && (
                        <Table.Cell>
                            <div style={styles.imageContainer}>
                                <ImageDefault fromRoot src={molds[0].image} ui={false} style={styles.workOrderImage} />
                            </div>
                        </Table.Cell>
                    )}
                    {!hideWorkOrderName && <Table.Cell>{customerName ? customerName : customer ? customer.customerName : ''}</Table.Cell>}
                    {!hideWorkOrder && (
                        <Table.Cell>
                            <a
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    navigator.clipboard.writeText(articleCode ? articleCode : workCode)
                                    this.props.success(createAlert('Codice copiato negli appunti!', ''))
                                }}
                            >{`${articleCode ? articleCode : workCode}`}</a>
                        </Table.Cell>
                    )}
                    {!hideWorkOrder && (
                        <Table.Cell>
                            <a href={route_from(app, articles, articleEdit, `${articleId ? articleId : articleArticleId}`)}>{`${
                                articleName ? articleName : workOrderDescription
                            }`}</a>
                        </Table.Cell>
                    )}
                    {!hideQuantity && <Table.Cell>{roundToDigits(productQuantity)}</Table.Cell>}
                    {!hideQuantity && (
                        <Table.Cell>
                            <Input
                                placeholder="Prodotto"
                                defaultValue={producedQuantity}
                                onChange={(event) => {
                                    // this.cursor = event.target.selectionStart

                                    let value = event.target.value >= 0 ? event.target.value : 0

                                    // console.log({ network })
                                    network.createWorkOrderDebounced({
                                        workOrderId,
                                        workCode,
                                        jobCode,
                                        producedQuantity: value,
                                    })
                                }}
                                onBlur={(e) => {
                                    this.setState({ producedQuantity: e.target.value })
                                }}
                                type="number"
                            />
                        </Table.Cell>
                    )}
                    {!hideQuantity && (
                        <Table.Cell>
                            <Input
                                placeholder="Consegnato"
                                defaultValue={deliveredQuantity}
                                onChange={(event) => {
                                    // this.cursor = event.target.selectionStart

                                    let value = event.target.value >= 0 ? event.target.value : 0

                                    // console.log({ network })
                                    network.createWorkOrderDebounced({
                                        workOrderId,
                                        workCode,
                                        jobCode,
                                        deliveredQuantity: value,
                                    })
                                }}
                                onBlur={(e) => {
                                    this.setState({ deliveredQuantity: e.target.value })
                                }}
                                type="number"
                            />
                        </Table.Cell>
                    )}
                    {!hideQuantity && (
                        <Table.Cell>
                            <Input
                                placeholder="Bollato"
                                defaultValue={stampedQuantity}
                                onChange={(event) => {
                                    // this.cursor = event.target.selectionStart

                                    let value = event.target.value >= 0 ? event.target.value : 0

                                    // console.log({ network })
                                    network.createWorkOrderDebounced({
                                        workOrderId,
                                        workCode,
                                        jobCode,
                                        stampedQuantity: value,
                                    })
                                }}
                                onBlur={(e) => {
                                    this.setState({ stampedQuantity: e.target.value })
                                }}
                                type="number"
                            />
                        </Table.Cell>
                    )}
                    {!hideQuantity && (
                        <Table.Cell
                            style={{
                                color: productQuantity - producedQuantity - stampedQuantity - deliveredQuantity <= 0 ? 'red' : 'black',
                            }}
                        >
                            {roundToDigits(productQuantity - producedQuantity - stampedQuantity - deliveredQuantity)}
                        </Table.Cell>
                    )}
                    {!hideBarcode && (
                        <Table.Cell>
                            <div className="barcode-container" ref={(el) => (this.componentRef = el)}>
                                <svg id={`workorderbarcode${workOrderInternalId ? workOrderInternalId : workOrderId}`}></svg>
                            </div>
                        </Table.Cell>
                    )}
                    {!noActions && !noWarehouse && this.state.workOrderId && (
                        <Table.Cell className="no-print">
                            <Popup
                                trigger={
                                    <Button
                                        icon
                                        color="grey"
                                        onClick={(e) => {
                                            this.props.network.createDocument(
                                                {
                                                    id_user: this.props.userid,
                                                    // id_deposit_from: 1,
                                                    // id_deposit_to: 1,
                                                    id_document_reason: 8,
                                                    // id_document_related: 1,
                                                    note: 'Documento generato',
                                                    date: moment().format('YYYY-MM-DD HH:mm:ss'),
                                                },
                                                [
                                                    {
                                                        code: workCode,
                                                        price: 0,
                                                        discount: '0',
                                                        qty: producedQuantity,
                                                        amount: producedQuantity,
                                                    },
                                                ]
                                            )
                                            e.stopPropagation()
                                        }}
                                    >
                                        <Icon name="arrow down" />
                                    </Button>
                                }
                                content="Genera documento di carico a magazzino con la quantità prodotta"
                            />
                            <Popup
                                trigger={
                                    <Button
                                        icon
                                        color="grey"
                                        onClick={(e) => {
                                            this.props.network.createDocument(
                                                {
                                                    id_user: this.props.userid,
                                                    // id_deposit_from: 1,
                                                    // id_deposit_to: 1,
                                                    id_document_reason: 7,
                                                    // id_document_related: 1,
                                                    note: 'Documento generato',
                                                    date: moment().format('YYYY-MM-DD HH:mm:ss'),
                                                },
                                                [
                                                    {
                                                        code: workCode,
                                                        price: 0,
                                                        discount: '0',
                                                        qty: producedQuantity,
                                                        amount: producedQuantity,
                                                    },
                                                ]
                                            )
                                            e.stopPropagation()
                                        }}
                                    >
                                        <Icon name="arrow up" />
                                    </Button>
                                }
                                content="Genera documento di scarico da magazzino con la quantità prodotta (spedizione o consumo)"
                            />
                        </Table.Cell>
                    )}
                    {!noActions && this.state.workOrderId && (
                        <Table.Cell className="no-print">
                            {/* <Button
                                icon
                                color="yellow"
                                onClick={(e) => {
                                    this.setState({
                                        mergingWorkOrder: true,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="exchange" />
                            </Button> */}
                            <SummarySheet type="workorder" objectToSerialize={this.state} />

                            <Button
                                icon
                                color="purple"
                                onClick={(e) => {
                                    network
                                        .createWorkOrder({
                                            workOrderId,
                                            workCode,
                                            jobCode,
                                            completed: true,
                                        })
                                        .then(async (result) => {
                                            this.setState({ completed: true })
                                        })
                                        .catch((error) => {})
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="check" />
                            </Button>

                            <MachinePicker
                                inProduction={inProduction}
                                onMachineChosen={(machine) => {
                                    // console.log('Adding production...')
                                    network
                                        .createLot({
                                            workOrderId,
                                            machineId: machine.machineId,
                                        })
                                        .then(async (result) => {
                                            this.setState({ inProduction: true })

                                            await network.createMachine({
                                                machineId: machine.machineId,
                                                activeLotId: result.lotId,
                                            })

                                            await network.createWorkOrder({
                                                workOrderId,
                                                workCode,
                                                jobCode,
                                                inProduction: true,
                                            })

                                            if (machine.machineHadesRef && machine.machineEndpoint) {
                                                if (machine.nodeJob) {
                                                    await network.createCommand({
                                                        node: machine.nodeJob,
                                                        action: 1,
                                                        value: `${jobCode}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                if (machine.nodeCustomer) {
                                                    await network.createCommand({
                                                        node: machine.nodeCustomer,
                                                        action: 1,
                                                        value: `${this.state.customerName}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                if (machine.nodeLot) {
                                                    await network.createCommand({
                                                        node: machine.nodeLot,
                                                        action: 1,
                                                        value: `${result.lotId}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                if (machine.nodeArticle) {
                                                    await network.createCommand({
                                                        node: machine.nodeArticle,
                                                        action: 1,
                                                        value: `${this.state.workCode}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                if (machine.nodeMaterial && this.state.materials && this.state.materials.length > 0) {
                                                    await network.createCommand({
                                                        node: machine.nodeMaterial,
                                                        action: 1,
                                                        value: `${this.state.materials[0].materialCode}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                if (machine.nodeMold && this.state.molds && this.state.molds.length > 0) {
                                                    await network.createCommand({
                                                        node: machine.nodeMold,
                                                        action: 1,
                                                        value: `${this.state.molds[0].moldCode}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })

                                                    if (machine.nodeMoldDescription) {
                                                        await network.createCommand({
                                                            node: machine.nodeMoldDescription,
                                                            action: 1,
                                                            value: `${this.state.molds[0].moldDescription}`,
                                                            endpoint: machine.machineEndpoint,
                                                        })
                                                    }

                                                    if (machine.nodeMoldId) {
                                                        await network.createCommand({
                                                            node: machine.nodeMoldId,
                                                            action: 1,
                                                            value: `${this.state.molds[0].moldCode}`,
                                                            endpoint: machine.machineEndpoint,
                                                        })
                                                    }

                                                    if (machine.nodeCavities) {
                                                        await network.createCommand({
                                                            node: machine.nodeCavities,
                                                            action: 1,
                                                            value: `${this.state.molds[0].piecesPerCycle ? this.state.molds[0].piecesPerCycle : 1}`,
                                                            endpoint: machine.machineEndpoint,
                                                        })
                                                    }
                                                }

                                                if (machine.nodeQuantity) {
                                                    await network.createCommand({
                                                        node: machine.nodeQuantity,
                                                        action: 1,
                                                        value: `${this.state.productQuantity - this.state.producedQuantity}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                // await this.props.network.createCommand({
                                                //     node: 'ns=2;i=20039',
                                                //     action: 1,
                                                //     value: `${job.jobCode}.ipr`,
                                                //     endpoint: 'opc.tcp://10.199.115.3:4842',
                                                // })

                                                if (machine.downEndpoint) {
                                                    await this.props.network.createCommand({
                                                        node: '',
                                                        action: 1,
                                                        value: `${this.state.molds[0].moldCode}.ipr`,
                                                        endpoint: `${machine.downEndpoint}`,
                                                    })
                                                }
                                            }
                                        })
                                        .catch((error) => {
                                            console.error('Error while starting production')
                                        })
                                }}
                            />
                            <Button
                                icon
                                color="orange"
                                disabled={!inProduction}
                                onClick={(e) => {
                                    network
                                        .createWorkOrder({
                                            workOrderId,
                                            workCode,
                                            jobCode,
                                            inProduction: false,
                                        })
                                        .then(async (result) => {
                                            this.setState({ inProduction: false })

                                            for (const lot of this.state.lots) {
                                                if (lot.activeLotId) {
                                                    await network.createMachine({
                                                        machineId: lot.machineId,
                                                        activeLotId: null,
                                                    })
                                                }
                                            }
                                        })
                                        .catch((error) => {})
                                }}
                            >
                                <Icon name="stop" />
                            </Button>
                            <Button
                                icon
                                color="blue"
                                onClick={(e) => {
                                    if (onRowClick) {
                                        onRowClick(this.state)
                                    }
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="edit" />
                            </Button>
                            <Button
                                icon
                                color="red"
                                onClick={(e) => {
                                    this.props.network.deleteWorkOrder({
                                        workOrderId,
                                        workCode,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="remove" />
                            </Button>
                            {/* <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button icon color="grey">
                                            <Icon name="print" />
                                        </Button>
                                    )
                                }}
                                content={() => this.componentRef}
                            /> */}
                        </Table.Cell>
                    )}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, workOrder } = this.props

        network.deleteStdWorkOrder(workOrder)

        this.setState({ deleting: false })
    }

    render() {
        let { mergeDestinations, mergingWorkOrder, workOrderId } = this.state
        let { login, type, workOrder, onClick, selected, noActions } = this.props

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(workOrder)
            }
        }

        let content = null
        switch (type) {
            case 'footer':
                content = this.renderTableFooter()
                break
            case 'header':
                content = this.renderTableHeader()
                break
            case 'table':
                content = this.renderForTable()
                break
            default:
                content = (
                    <Box
                        borderWidth="1px"
                        borderRadius="lg"
                        p="4"
                        overflow="hidden"
                        className={selected ? 'rowSelected' : ''}
                        style={styles.workOrderCard}
                        {...extraprops}
                    />
                )
                break
        }

        return (
            <>
                <Modal
                    key={`modal_workOrder_${workOrderId}`}
                    closeIcon
                    open={!!mergingWorkOrder}
                    onClose={() => this.setState({ mergingWorkOrder: false })}
                >
                    <Modal.Header>Modificando workOrdere</Modal.Header>
                    <Modal.Content>
                        <WorkOrderDropdown
                            selected={mergeDestinations}
                            onChange={(ev, data, selected, workOrders) => {
                                this.setState({
                                    mergeDestinations: workOrders,
                                })
                            }}
                            onlySelection
                        />
                        <Button
                            style={{ marginTop: 16 }}
                            color="red"
                            onClick={() => {
                                this.props.network.mergeWorkOrder({
                                    sourceId: this.state.workOrderId,
                                    destinationId: this.state.mergeDestinations[0].workOrderId,
                                })
                                this.setState({ mergingWorkOrder: false })
                            }}
                            disabled={
                                !this.state.mergeDestinations ||
                                this.state.mergeDestinations.length !== 1 ||
                                this.state.workOrderId === this.state.mergeDestinations[0].workOrderId
                            }
                        >
                            Effettua unione AZIONE IRREVERSIBILE, VERRA{"'"} ELIMINATO IL MATERIALE {'"'}
                            {this.state.jobCode}
                            {'"'} ({this.state.workOrderId})
                        </Button>
                    </Modal.Content>
                </Modal>
                {content}
            </>
        )
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    workOrderImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    workOrderContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    workOrderCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getWorkOrder } = state
    let rworkOrderId = null
    let userid = null
    if (login && login.data && login.data.user) {
        rworkOrderId = login.data.user.rworkOrderId
        userid = login.data.user.id_user
    }

    return { role: rworkOrderId, getWorkOrder, login, userid }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(WorkOrder)))
