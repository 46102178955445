import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routeNames, routes, route_from } from 'lib/routes'
import 'moment/locale/it'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
import { Breadcrumb, Button, Checkbox, Form, Icon, Input, Menu, Modal, Select } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { ORGANIZATION_ACTIONS } from '../actions/organization'
import { SEARCH_ACTIONS } from '../actions/search'
import { Recap } from '../components'
import ThemeButton from './ThemeButton'

const moment = require('moment-timezone')
moment.tz.setDefault('Europe/Rome')
moment.locale('it')

const {
    login: loginRoute,
    app,
    finalBalance,
    jobEdit,
    jobMerge,
    workerEdit,
    machineEdit,
    stockEdit,
    documentEdit,
    depositEdit,
    categoryEdit,
    corpEdit,
    costEdit,
    costUpdate,
    userEdit,
    turnEdit,
    productEdit,
    employee,
    job,
    newEl,
    collectionEdit,
    familyEdit,
    articleEdit,
    variantEdit,
    officeEdit,
    clientOrderEdit,
} = routes

const months = [
    { key: 0, text: 'Gennaio', value: 0 },
    { key: 1, text: 'Febbraio', value: 1 },
    { key: 2, text: 'Marzo', value: 2 },
    { key: 3, text: 'Aprile', value: 3 },
    { key: 4, text: 'Maggio', value: 4 },
    { key: 5, text: 'Giugno', value: 5 },
    { key: 6, text: 'Luglio', value: 6 },
    { key: 7, text: 'Agosto', value: 7 },
    { key: 8, text: 'Settembre', value: 8 },
    { key: 9, text: 'Ottobre', value: 9 },
    { key: 10, text: 'Novembre', value: 10 },
    { key: 11, text: 'Dicembre', value: 11 },
]

class ToolBar extends Component {
    constructor(props) {
        super(props)

        let updateSearch = debounce(500, false, (search) => {
            this.props.searchResult(search)
        })

        this.state = {
            search: '',
            updateSearch,
            date: new Date(),
            recapModal: false,
            minus: true,
            wait: true,
            year: moment().get('year'),
            month: moment().get('month'),
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { searchData } = this.props

        if (prevState.currentCompanyIndex !== this.state.currentCompanyIndex) {
            // console.log('Calling organization result with company index', this.state.currentCompanyIndex)
            this.props.organizationResult([this.state.currentCompanyIndex])
        }

        if (searchData !== prevProps.searchData && searchData !== this.state.search) {
            this.setState({
                search: searchData,
            })
        }
    }

    componentDidMount() {
        let { login } = this.props

        if (login.authenticated) {
            this.setState({
                currentCompany: login.data.user.companies[0],
                currentCompanyIndex: 0,
            })
        }

        this.props.organizationResult([0])

        this.props.searchResult('')
    }

    clearSearch() {
        this.setState({
            search: '',
        })
        this.props.searchResult('')
    }

    skipCheck(toCheck) {
        let skip = false
        let skipList = [
            routeNames[app],
            routeNames[finalBalance],
            routeNames[jobEdit],
            routeNames[userEdit],
            routeNames[jobMerge],
            routeNames[workerEdit],
            routeNames[machineEdit],
            routeNames[costEdit],
            routeNames[costUpdate],
            routeNames[employee],
            routeNames[job],
            routeNames[newEl],
            routeNames[turnEdit],
            routeNames[stockEdit],
            routeNames[productEdit],
            routeNames[depositEdit],
            routeNames[categoryEdit],
            routeNames[corpEdit],
            routeNames[documentEdit],
            routeNames[collectionEdit],
            routeNames[familyEdit],
            routeNames[articleEdit],
            routeNames[variantEdit],
            routeNames[officeEdit],
            routeNames[clientOrderEdit],
        ]
        toCheck = toCheck.replace('/', '')

        for (let i in skipList) {
            let route = skipList[i].replace('/', '')
            if (route === toCheck) {
                skip = true
                break
            }
        }

        return skip
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    render() {
        let { search, recapModal, year, month, minus, wait } = this.state
        let {
            network,
            history,
            location: { pathname },
            isEmployee,
            login,
            loginEmployee,
        } = this.props
        let bcs = pathname.split('/')
        let breadcrumbs = []
        let curpath = ''
        for (let i in bcs) {
            curpath += bcs[i]
            if (i < bcs.length - 1) {
                curpath += '/'
            }
            let skip = false

            if (this.skipCheck(bcs[i])) {
                skip = true
            }

            breadcrumbs.push({
                path: curpath,
                name: bcs[i],
                skip,
            })
        }

        let companyOptions = []
        if (login && login.data.user && login.data.user.companies) {
            for (let i in login.data.user.companies) {
                let company = login.data.user.companies[i]
                companyOptions.push({
                    key: company,
                    value: company,
                    text: company,
                })
            }
        }

        return (
            <Menu
                borderless
                size="large"
                style={{
                    // zIndex: 43,
                    borderRadius: 'unset',
                    borderLeft: 'unset',
                    borderTop: 'unset',
                    borderRight: 'unset',
                    margin: 0,
                    //TODO Resp
                    ...(!isMobile && { width: 'calc(100vw - 110px)' }),
                    overflow: 'auto',
                }}
            >
                {/* TODO Resp */}
                <Menu.Item
                    name="arrow left"
                    onClick={() => {
                        history.goBack()
                    }}
                    style={isMobile ? { padding: 0 } : {}}
                >
                    <Icon size="large" name="arrow left" style={{ ...(!isMobile && { color: 'white' }), zIndex: 101 }} />
                </Menu.Item>

                {/* <Default> */}
                <Menu.Item
                    style={{
                        ...(!isMobile && { marginLeft: 20 }),
                        ...(isMobile && { padding: 0 }),
                    }}
                    content={
                        <Breadcrumb size={isMobile ? 'small' : 'large'}>
                            {breadcrumbs.map((value, index) => {
                                let extraProps = {}
                                if (!value.skip) {
                                    extraProps = {
                                        link: true,
                                        as: NavLink,
                                        to: value.path,
                                    }
                                }
                                return (
                                    <span key={index}>
                                        {index !== 0 && <Breadcrumb.Divider />}
                                        <Breadcrumb.Section style={{ color: 'black' }} {...extraProps} active={index === breadcrumbs.length - 1}>
                                            {value.name}
                                        </Breadcrumb.Section>
                                    </span>
                                )
                            })}
                            <Breadcrumb.Divider icon="right angle" />

                            {search && <Breadcrumb.Divider icon="random" />}
                            {search && <Breadcrumb.Section active>Ricerca: {search}</Breadcrumb.Section>}
                        </Breadcrumb>
                    }
                />
                {/* </Default> */}
                <Menu.Menu position="right">
                    {!isEmployee && (
                        <Menu.Item>
                            <Input
                                icon={
                                    this.state.search.length > 0 ? (
                                        <Icon name="delete" link onClick={() => this.clearSearch()} />
                                    ) : (
                                        <Icon name="search" />
                                    )
                                }
                                placeholder="Cerca..."
                                value={this.state.search}
                                onChange={(e, data) => {
                                    let text = data.value
                                    this.state.updateSearch(text)
                                    this.setState({ search: text })
                                }}
                            />
                        </Menu.Item>
                    )}
                    {!isEmployee && companyOptions.length > 1 && (
                        <Menu.Item>
                            <Select
                                placeholder="Azienda"
                                options={companyOptions}
                                value={this.state.currentCompany}
                                onChange={(e, data) => {
                                    // console.log('Selected company', data.value, this.state.currentCompany)
                                    let currentCompanyIndex = 0
                                    for (let j in companyOptions) {
                                        if (companyOptions[j].key === data.value) {
                                            currentCompanyIndex = j
                                            break
                                        }
                                    }

                                    this.setState({
                                        currentCompany: data.value,
                                        currentCompanyIndex,
                                    })
                                }}
                            />
                        </Menu.Item>
                    )}
                    <ThemeButton />
                    {!isEmployee && (
                        <Menu.Item
                            name="pdf"
                            onClick={() => {
                                this.setState({ recapModal: true })
                            }}
                        >
                            <Icon size="large" name="file pdf outline" />
                            <span>Riepilogo</span>
                        </Menu.Item>
                    )}
                    {isEmployee && (
                        <Menu.Item>
                            <Box textStyle="header">{loginEmployee && loginEmployee.data.user ? loginEmployee.data.user.name : ''}</Box>
                        </Menu.Item>
                    )}
                    {isEmployee && (
                        <Menu.Item>
                            <Box textStyle="header">
                                {network.getCookie('terminal_machine') ? network.getCookie('terminal_machine').machineName : ''}
                            </Box>
                        </Menu.Item>
                    )}
                    {isEmployee && (
                        <Menu.Item
                            name="changemachine"
                            onClick={() => {
                                network.removeCookie('terminal_machine')
                                network.logoutEmployee()
                                history.replace(route_from(employee))
                            }}
                        >
                            <Icon size="large" name="hdd outline" />
                        </Menu.Item>
                    )}
                    {/* {isEmployee && (
						<Menu.Item
							name="calendar"
							onClick={() => {
								this.props.history.replace(route_from(employee, insertion, calendarView))
							}}
						>
							<Icon size="large" name="calendar alternate outline" />
							<span>Calendario</span>
						</Menu.Item>
					)} */}
                    <Menu.Item
                        name="logout"
                        onClick={() => {
                            if (isEmployee) {
                                network.logoutEmployee()
                                history.replace(route_from(employee))
                                return
                            }

                            network.logout()
                            history.replace(route_from(loginRoute))
                        }}
                    >
                        <Icon size="large" name="sign out" />
                    </Menu.Item>
                </Menu.Menu>

                <Modal open={recapModal}>
                    <Modal.Header>Creazione Riepilogo</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Field
                                    id="form-input-control-recap-year"
                                    control={Input}
                                    type="number"
                                    label={<span className="label">Anno</span>}
                                    placeholder="2019"
                                    step="1"
                                    name="year"
                                    value={year}
                                    onChange={(e) => this.handleInput(e)}
                                />
                                <Form.Field
                                    id="form-input-control-job-type"
                                    control={Select}
                                    options={months}
                                    label={<span className="label">Mese</span>}
                                    placeholder="Mese"
                                    name="month"
                                    value={month}
                                    onChange={(e, data) => {
                                        this.setState({ month: data.value })
                                    }}
                                />
                            </Form.Group>
                            <Form.Field
                                control={Checkbox}
                                label={<span className="label">Con sottrazione ore operaio dalle ore macchina</span>}
                                checked={minus}
                                onChange={(e, data) => {
                                    // console.log('Setting minus to', data.checked)
                                    this.setState({ minus: data.checked })
                                }}
                            />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            primary
                            icon
                            onClick={() => {
                                this.setState({ wait: true, recapModal: false })
                            }}
                        >
                            Annulla <Icon name="cancel" />
                        </Button>
                        <Button
                            primary
                            icon
                            onClick={() => {
                                this.setState({ wait: false })
                            }}
                        >
                            Calcola <Icon name="computer" />
                        </Button>
                        <Recap
                            year={year}
                            month={month + 1}
                            minus={minus}
                            wait={wait}
                            onDone={(data) => {
                                // console.log('On done called', data)
                                this.setState({ wait: true })
                            }}
                            element={
                                <Button
                                    primary
                                    icon
                                    onClick={() => {
                                        this.setState({ wait: true, recapModal: false })
                                    }}
                                >
                                    Scarica <Icon name="download" />
                                </Button>
                            }
                        />
                    </Modal.Actions>
                </Modal>
            </Menu>
        )
    }
}

/*
{!isEmployee && (
							<Popup trigger={<Icon size="large" name="calendar alternate outline" />} flowing hoverable>
								<Calendar onChange={(date) => this.setState({ date })} value={this.state.date} />
							</Popup>
	<Menu.Item
		size="large"
		name="emp_login"
		onClick={() => {
			network.logout()
			history.replace(route_from(daconsole, pin))
		}}>
		<Icon name="terminal" />
		<span>Console</span>
	</Menu.Item>
)}
*/

const mapDispatchToProps = (dispatch) => {
    return {
        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

        organizationRequest: (result) => ORGANIZATION_ACTIONS.request(result, dispatch),
        organizationResult: (result) => ORGANIZATION_ACTIONS.result(result, dispatch),
        organizationError: (result) => ORGANIZATION_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        login,
        loginEmployee,
        search: { data: searchData },
    } = state

    return {
        login,
        loginEmployee,
        searchData,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(ToolBar)))
