import { connectNetwork } from 'lib/NetworkProvider'
import React from 'react'
import ReactExport from 'react-data-export'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Loader } from 'semantic-ui-react'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const compute = function (forcedJob, forcedWorks, minus) {
    let { works: fromProps } = this.props
    if (!fromProps) fromProps = []
    let works = fromProps.slice(0)
    if (forcedWorks) works = forcedWorks.slice(0)

    /*
    // console.log('Computing with this work list');
    console.dir(works);
    console.dir(forcedWorks);
    console.dir(fromProps);
    */

    let workers = []
    let workerobj = {}
    let machines = []
    let machineobj = {}
    let totalWorkerTime = [0, 0]
    let totalMachineTime = [0, 0]

    let workdict = {}
    let costdict = {}
    let costs = []
    let notWorks = [] // Cost entries don't have a work attached to them

    let { costs: costsFromProps } = this.props
    if (costsFromProps) {
        costs = costsFromProps
        for (let i in costs) {
            costs[i].costPrice = parseFloat(costs[i].costPrice)
        }
    }

    //console.log('Job costs from props:', costsFromProps);
    for (let i in works) {
        let work = works[i]
        let {
            jobCode,
            workerId,
            machineId,
            workerName,
            machineName,
            workerHours,
            machineHours,
            workerMinutes,
            machineMinutes,
            workerHourlyRate,
            machineHourlyRate,
            costId,
            costDate,
            costDescription,
            producer,
            costPrice,
            registrationDate,
        } = work

        if (minus) {
            machineHours = Math.max(0, machineHours - workerHours)
            machineMinutes = machineMinutes - workerMinutes
            if (machineMinutes < 0 && machineHours > 0) {
                machineHours = machineHours - 1
                machineMinutes = 60 + machineMinutes
            } else if (machineMinutes < 0) machineMinutes = 0
        }

        //console.log("costId", costId, "in", costdict);
        if (!costsFromProps && costId !== null && costId && !costdict[costId]) {
            //console.log('Pushing work', work, 'as cost');
            costdict[costId] = {
                costId,
                costDate,
                jobCode,
                costDescription,
                producer,
                costPrice,
            }
            costs.push(costdict[costId])
        }

        if (!workdict[`${jobCode}_${workerId}_${registrationDate}`]) {
            workdict[`${jobCode}_${workerId}_${registrationDate}`] = true
        } else {
            notWorks.push(parseInt(i))
            continue
        }

        workerHours = parseFloat(workerHours)
        workerMinutes = parseFloat(workerMinutes)
        machineHours = parseFloat(machineHours)
        machineMinutes = parseFloat(machineMinutes)
        totalWorkerTime[0] += workerHours
        totalWorkerTime[1] += workerMinutes
        totalMachineTime[0] += machineHours
        totalMachineTime[1] += machineMinutes

        if (workerId !== null) {
            if (!workerobj[workerId]) {
                workerobj[workerId] = []
            }

            workerobj[workerId].push({
                workerId,
                workerName,
                workerHours,
                workerMinutes,
                workerHourlyRate,
            })
        }

        if (machineId !== null) {
            if (!machineobj[machineId]) {
                machineobj[machineId] = []
            }

            machineobj[machineId].push({
                machineId,
                machineName,
                machineHours,
                machineMinutes,
                machineHourlyRate,
            })
        }
    }

    for (let i = notWorks.length - 1; i >= 0; i--) {
        let toRemove = notWorks[i]
        works.splice(toRemove, 1)
    }

    for (let i in workerobj) {
        let worklist = workerobj[i]
        let { workerId, workerName } = worklist[0]
        let workerHours = 0
        let workerMinutes = 0
        let workerHourlyRate = 0

        for (let j in worklist) {
            let work = worklist[j]
            workerHours += work.workerHours
            workerMinutes += work.workerMinutes
            workerHourlyRate = parseFloat(work.workerHourlyRate)
        }

        workerHours += Math.floor(workerMinutes / 60)
        workerMinutes = workerMinutes % 60

        workers.push({
            workerId,
            workerName,
            workerHours,
            workerMinutes,
            workerHourlyRate,
        })
    }

    for (let i in machineobj) {
        let worklist = machineobj[i]
        let { machineId, machineName } = worklist[0]
        let machineHours = 0
        let machineMinutes = 0
        let machineHourlyRate = 0

        for (let j in worklist) {
            let work = worklist[j]
            machineHours += work.machineHours
            machineMinutes += work.machineMinutes
            machineHourlyRate = parseFloat(work.machineHourlyRate)
        }

        machineHours += Math.floor(machineMinutes / 60)
        machineMinutes = machineMinutes % 60

        machines.push({
            machineId,
            machineName,
            machineHours,
            machineMinutes,
            machineHourlyRate,
        })
    }

    let colors = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black']
    let color = this.props.color
    if (!color) {
        color = colors[parseInt(Math.random() * colors.length)]
    }

    let { job } = this.props

    totalWorkerTime[0] += Math.floor(totalWorkerTime[1] / 60)
    totalWorkerTime[1] = totalWorkerTime[1] % 60
    totalMachineTime[0] += Math.floor(totalMachineTime[1] / 60)
    totalMachineTime[1] = totalMachineTime[1] % 60

    //console.log("Final works list", works);

    return {
        costs,
        workers,
        works,
        machines,
        totalWorkerTime,
        totalMachineTime,
        color,
        job: forcedJob ? forcedJob : job,
    }
}

class Recap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            recapDone: false,
            workerDone: false,
            machineDone: false,
        }
    }

    componentDidMount() {
        let { year, month, minus, network, wait } = this.props
        // console.log('Recapping year', year, 'month', month, 'minus', minus)
        if (!wait) {
            network.recap(year, month)
            network.getWorkerList('')
            network.getMachineList('')
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { year, month, recap, getWorkerList, getMachineList, minus, wait, network, onDone } = this.props
        let { recapDone, workerDone, machineDone } = this.state

        if (!recap.fetching && prevProps.recap.fetching) {
            this.setState({ recapDone: true })
        }
        if (!getWorkerList.fetching && prevProps.getWorkerList.fetching) {
            this.setState({ workerDone: true })
        }
        if (!getMachineList.fetching && prevProps.getMachineList.fetching) {
            this.setState({ machineDone: true })
        }
        /*
		if (!prevProps.wait && wait) {
			this.setState({
				recapDone: false,
				workerDone: false,
				machineDone: false
			});
		}
		*/
        if (prevProps.wait && !wait) {
            // console.log('Recapping year', year, 'month', month, 'minus', minus)
            network.recap(year, month)
            network.getWorkerList('')
            network.getMachineList('')
        }

        if (
            recapDone &&
            workerDone &&
            machineDone &&
            !wait &&
            (!(prevState.recapDone && prevState.workerDone && prevState.machineDone) || (!wait && prevProps.wait))
        ) {
            let computefn = compute.bind(this)
            let joblist = []
            let workers = {}
            let machines = {}
            for (let i in getWorkerList.data.data) {
                let worker = getWorkerList.data.data[i]
                workers[worker.workerId] = {
                    ...worker,
                    totalPerJob: {},
                    totalTime: 0,
                }
            }

            for (let i in getMachineList.data.data) {
                let machine = getMachineList.data.data[i]
                machines[machine.machineId] = {
                    ...machine,
                    totalPerJob: {},
                    totalTime: 0,
                }
            }

            if (recap.data.data) {
                let jobs = {}
                for (let i in recap.data.data) {
                    let row = recap.data.data[i]
                    if (!jobs[row.jobName ? row.jobName : row.jobCode]) {
                        jobs[row.jobName ? row.jobName : row.jobCode] = []
                    }
                    jobs[row.jobName ? row.jobName : row.jobCode].push(row)
                }

                for (let jobCode in jobs) {
                    let job = jobs[jobCode]
                    joblist.push({
                        ...job[0],
                        ...computefn(jobCode, job, minus),
                    })
                }
            }

            /* eslint-disable */
            console.log('Joblist is', joblist)
            for (let i in joblist) {
                let job = joblist[i]
                let { machines: ms, workers: ws, job: jobCode } = job
                for (let j in ws) {
                    let worker = ws[j]
                    let partial = worker.workerHours + worker.workerMinutes / 60
                    workers[worker.workerId].totalTime += partial
                    if (!workers[worker.workerId].totalPerJob[jobCode]) {
                        workers[worker.workerId].totalPerJob[jobCode] = 0
                    }
                    workers[worker.workerId].totalPerJob[jobCode] += partial
                }
                for (let j in ms) {
                    let machine = ms[j]
                    let partial = machine.machineHours + machine.machineMinutes / 60
                    machines[machine.machineId].totalTime += partial
                    if (!machines[machine.machineId].totalPerJob[jobCode]) {
                        machines[machine.machineId].totalPerJob[jobCode] = 0
                    }
                    machines[machine.machineId].totalPerJob[jobCode] += partial
                }
            }

            let excelfile = []
            for (let i in joblist) {
                let job = joblist[i]

                let row = {
                    client: job.client,
                    description: job.jobDescription,
                    job: job.job,
                }

                let total = 0
                for (let j in workers) {
                    let worker = workers[j]
                    let partial = worker.totalPerJob[job.job]
                    row[`worker-${worker.workerId}`] = partial ? partial : 0
                    total += partial ? partial : 0
                }

                for (let j in machines) {
                    let machine = machines[j]
                    let partial = machine.totalPerJob[job.job]
                    row[`machine-${machine.machineId}`] = partial ? partial : 0
                    total += partial ? partial : 0
                }
                row.total = total
                excelfile.push(row)
            }

            let lastrow = {
                client: `${year}`,
                description: `${month}`,
                job: minus ? 'con-sottrazione' : 'normale',
            }
            let lastTotal = 0

            for (let j in workers) {
                let worker = workers[j]
                let partial = worker.totalTime
                lastrow[`worker-${worker.workerId}`] = partial ? partial : 0
                lastTotal += partial
            }

            for (let j in machines) {
                let machine = machines[j]
                let partial = machine.totalTime
                lastrow[`machine-${machine.machineId}`] = partial ? partial : 0
                lastTotal += partial
            }
            lastrow.total = lastTotal
            excelfile.push(lastrow)

            let workerlist = []
            for (let i in workers) {
                workerlist.push(workers[i])
            }
            let machinelist = []
            for (let i in machines) {
                if (machines[i].machineId === 1) continue
                machinelist.push(machines[i])
            }

            if (onDone) {
                onDone({
                    jobs: joblist,
                    workers: workerlist,
                    machines: machinelist,
                    excelfile,
                })
            }

            this.setState({
                jobs: joblist,
                workers: workerlist,
                machines: machinelist,
                excelfile,
            })
        }
    }

    render() {
        let { recap, getWorkerList, getMachineList, element, year, month, minus } = this.props
        let { workers, machines, excelfile, recapDone, workerDone, machineDone } = this.state

        if (recap.fetching || getWorkerList.fetching || getMachineList.fetching) {
            return <Loader active />
        }
        if (!recapDone || !workerDone || !machineDone) {
            return null
        } else {
            return (
                <ExcelFile
                    filename={`riepilogo_${year}_${month}_` + (minus ? 'con-sottrazione' : 'normale')}
                    element={element ? element : <Button>Download</Button>}
                >
                    <ExcelSheet data={excelfile} name="Riepilogo">
                        <ExcelColumn label="Cliente" value="client" />
                        <ExcelColumn label="Descrizione" value="description" />
                        <ExcelColumn label="Codice commessa" value="job" />
                        {workers &&
                            workers.map((value, index) => {
                                return <ExcelColumn key={`worker-${index}`} label={value.workerName} value={`worker-${value.workerId}`} />
                            })}
                        {machines &&
                            machines.map((value, index) => {
                                return <ExcelColumn key={`machine-${index}`} label={value.machineName} value={`machine-${value.machineId}`} />
                            })}
                        <ExcelColumn label="Totale" value="total" />
                    </ExcelSheet>
                </ExcelFile>
            )
        }
    }
    /*
    <ExcelColumn label={<span className="label">Name" value="name</span>} />
    <ExcelColumn label={<span className="label">Wallet Money" value="amount</span>} />
    <ExcelColumn label={<span className="label">Gender" value="sex</span>} />
    <ExcelColumn label={<span className="label">Marital Status</span>} value={(col) => (col.is_married ? 'Married' : 'Single')} />
    */
}

const mapStateToProps = (state) => {
    let { login, recap, getWorkerList, getMachineList } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, recap, getWorkerList, getMachineList }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Recap)))
