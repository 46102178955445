import { useDisclosure } from '@chakra-ui/hooks'
import { Box } from '@chakra-ui/layout'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon } from 'semantic-ui-react'
import { Machines } from '../containers'
import { baseViewDispatch } from '../lib/util'
function MachinePicker(props) {
    const { network, toolbarResult, filtersResult, sortsResult, isEmployee, onMachineChosen, inProduction, machineType = 8 } = props

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [machine, setMachine] = useState(null)

    return (
        <>
            <Button icon color="green" onClick={onOpen} disabled={!!inProduction}>
                <Icon name="play" />
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW="90vw">
                    <ModalHeader>Scegli macchina su cui avviare la produzione</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody maxW="90vw">
                        <Box h="70vh" overflow="scroll">
                            <Machines
                                selectable
                                onChange={(machine) => {
                                    setMachine(machine)
                                }}
                                sub
                                noActions
                            />
                        </Box>
                        <Button
                            onClick={() => {
                                if (onMachineChosen) {
                                    onMachineChosen(machine)
                                }
                                onClose()
                            }}
                        >
                            Conferma
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteMachine, mergeMachine } = state

    return {
        getMachineList,
        deleteMachine,
        mergeMachine,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(MachinePicker)))
